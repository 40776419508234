<template>
  <transition name="fade">
    <div v-show="pageList.length > 0 && total > 0" class="pagination">
      <div v-if="mode === 'multiple'" class="icon" :class="{ disabled: currentPage === 1 }" @click="setPage(1)">
        <img src="@/assets/icons/arrow-first.svg" alt="">
      </div>
      <div class="icon" :class="{ disabled: currentPage === 1 }" @click="changePage(-1)">
        <img src="@/assets/icons/arrow-left.svg" alt="">
      </div>
      <ul :class="{ 'fit-width': widthFit }">
        <li 
          v-for="item in pageList" 
          :key="item" 
          :class="{ active: item === currentPage, 'multiple-width': mode === 'multiple' }"
          @click="setPage(item)"
        >
          <span>{{ item }}</span>
        </li>
      </ul>
      <div class="icon" :class="{ disabled: currentPage === totalPages }" @click="changePage(1)">
        <img src="@/assets/icons/arrow-right.svg" alt="">
      </div>
      <div v-if="mode === 'multiple'" class="icon" :class="{ disabled: currentPage === totalPages }" @click="setPage(totalPages)">
        <img src="@/assets/icons/arrow-last.svg" alt="">
      </div>
      <div class="page-info">
        <div v-if="mode === 'multiple'" class="go-page">
          <span>{{ $t('go')/*前往*/ }}</span>
          <Select 
            class="page-select"
            v-model="selectedPage" 
            :options="pageOptions"
            :multiSelect="false"
          />
          <span>{{ $t('history_event_pages')/*頁*/ }}</span>
        </div>
        <span class="count-no">{{ countNo }}</span>
        <span>{{ totalCountPage }}</span>
      </div>
      
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Pagination',
  components: {
    Select: () => import('@/components/History/SearchBar/Select.vue')
  },
  props: {
    mode: {
      type: String,
      default: 'multiple' // multiple: 多頁切換, single: 單頁切換
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
  },
  data() {
    return {
      // 數值對應頁籤選項的數量 。ex: 數值1～97對應7個選項, >=11位數則只有1個選項
      dict: { 
        1:7, 
        98: 6, 
        998: 5, 
        9999: 4, 
        99999: 3, 
        1000000:2, 
        10000000000: 1
      }
    }
  },
  computed: {
    pageList() {
      if (this.mode === 'single') return [this.currentPage]
      if (this.currentPage <= 7 && this.totalPages <= 7) {
        return Array.from({ length: this.totalPages }, (v, i) => i + 1)
      }
      const pageCount = this.getPageCount(this.currentPage)
      const list = []
      const start = this.currentPage - parseInt(pageCount / 2)

      for (let i = 0; i < pageCount; i++) {
        if (start <= 0) list.push(i + 1)
        else if (this.totalPages - pageCount + 1 <= this.currentPage) list.push(this.totalPages - pageCount + i + 1 )
        else if (start + i >= 1) list.push(start + i)
        
      }
      return this.mode === 'multiple' ? list : [this.currentPage]
    },
    totalPages() {
      return Math.ceil(this.total / this.pageSize)
    },
    widthFit() {
      return (this.currentPage <= 4 && this.totalPages < 7) || this.mode === 'single'
    },
    pageOptions() {
      return Array.from({ length: this.totalPages }, (v, i) => i + 1).map(item => ({ label: item, value: item }))
    },
    selectedPage: {
      get() {
        return this.currentPage
      },
      set(val) {
        this.setPage(val)
      }
    },
    countNo() {
      const countStart = (this.currentPage - 1) * this.pageSize + 1
      const countEnd = (this.total - countStart) >= this.pageSize ? this.currentPage * this.pageSize : this.total
      return `${this.$t('history_event_no')}${countStart} - ${countEnd}${this.$t('search_of_events')}`
    },
    totalCountPage() {
      // 格式：(共1000筆，共100頁)
      return `(${this.$t('search_total_of')}${this.total}${this.$t('search_of_events')}，${this.$t('search_total_of')}${this.totalPages}${this.$t('history_event_pages')})`
    }
  },
  methods: {
    getPageCount(k) {
      let keys = Object.keys(this.dict).map(Number).sort((a, b) => a - b)
      let keyIdx = keys.findIndex(key => k < key)

      if (keyIdx !== -1) {
        const idx = keyIdx === 0 ? 0 : keyIdx - 1
        return this.dict[keys[idx]]
      } else if (k >= keys[keys.length - 1]) {
        return this.dict[keys[keys.length - 1]]
      } else {
        return 0
      }
    },
    changePage(index) {
      let newPage = Number(this.currentPage) + index
      if (newPage < 1 || newPage > this.totalPages) return
      this.$emit('getPageData', newPage)
    },
    setPage(pageNo) {
      if (this.currentPage === pageNo) return
      this.$emit('getPageData', pageNo)
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  column-gap: 16px;
  .icon {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      img {
        filter: brightness(0) saturate(100%) invert(78%) sepia(60%) saturate(2415%) hue-rotate(359deg) brightness(103%) contrast(104%);
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        img {
          filter: none;
        }
      }
    }
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    width: 290px;
    display: flex;
    &.fit-width {
      width: auto;
    }
    li {
      flex: 1;
      font-family: Roboto;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #FFC600;
        font-weight: bold;
        span {
          border-bottom: 3px solid #FFC600;
        }
      }
      &.multiple-width {
        min-width: 42px;
      }
    }
  }
}

.page-info {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  .go-page {
    display: flex;
    align-items: center;
    margin-right: 20px;
    span:first-child {
      white-space: nowrap;
    }
    span:last-child {
      color: #ffffff;
    }
  }
  
  span {
    font-size: px2rem(18);
    &.count-no {
      margin-left: 20px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 28px;
        background: #ffffff80;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:last-child {
      color: #FFE99F;
    }
  }
  .page-select {
    margin: 0 4px;
    box-sizing: border-box;
    :deep .select-selected {
      display: flex;
      align-items: center;
      height: 26px;
      line-height: 26px;
      border-radius: 3px;
      border: 1px solid #ffffff80;
      background: #282942;
      color: #ffffff;
      text-align: center;
      padding: 0 18px;
      &::after {
        display: none;
      }
      img {
        display: none;
      }
    }

    :deep .select-options {
      border: 1px solid #ffffffcc;
      border-radius: 3px;
      background: #191919cc;
      margin-top: 2px;
      .options {
        max-height: 260px;
        overflow-y: auto;
      }
        .select-option {
        font-size: 18px;
        line-height: 24px;
        word-break: normal;
        &.selected {
          color: #FFC600;
        }
      }
    }
  }
}
</style>